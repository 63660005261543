<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="register">
        <p>
            <input type="number" placeholder="请输入手机号" @keyup.enter="zhuce()" @blur="phone_shi" @focus="phone_ju" v-model="phone">
            <span v-if="phone_geshi" class="tishi animated fadeInRight">手机号码格式错误</span>
            <span v-if="phone_zhuce" class="tishi animated fadeInRight">该号码已注册</span>
            <i class="el-icon-error" @click="phone=''"></i>
        </p>
        <p>
            <input type="text" placeholder="请输入验证码" @keyup.enter="zhuce()" v-model="code">
            <span v-if="sj==0" class="register_yanzheng" @click="dianji_yanzheng_ma">发送验证码</span>
            <span v-if="sj!=0" class="register_yanzheng">{{sj+'s'}}</span>
        </p>
        <p>
            <input :type="look?'text':'password'" placeholder="请输入（6-20）位数字与字母组合密码" @blur="pwd1_shi" @focus="pwd1_ju"  @keyup.enter="zhuce()" v-model="pwd1">
            <span v-if="pwd1_geshi1" class='tishi animated fadeInRight'>请输入（6-20）位数字与字母组合密码</span>
            <span v-if="pwd1_geshi2" class='zhengque animated fadeInRight'>格式正确</span>
            <img class="yanjing" @click="look=!look" :src="look?require('../../assets/eye.png'):require('../../assets/eye-no.png')" alt="">
        </p>
        <p>
            <input :type="look2?'text':'password'" placeholder="请重复密码" @blur="pwd2_shi" @focus="pwd2_ju" @keyup.enter="zhuce()" v-model="pwd2">
            <span v-if="pwd2_chongfu" class="tishi animated fadeInRight">与上述密码保持一致</span>
            <img class="yanjing" @click="look2=!look2" :src="look2?require('../../assets/eye.png'):require('../../assets/eye-no.png')" alt="">
        </p>
        <p @click="zhuce">注册</p>
        <p>点击注册即表示同意<span @click="dianji_xiazai_yisi">《隐私保护政策》</span></p>
    </div>
</template>

<script>
import { register, sms, register_judge } from '../../api/api.js'
export default {
  name: 'register',
  data () {
    return {
      look: false,
      look2: false,
      phone_geshi: false,
      phone_zhuce: false,
      phone: '',
      code: '',
      pwd1: '',
      pwd2: '',
      sj: 0,
      pwd1_geshi1: false,
      pwd1_geshi2: false,
      pwd2_chongfu: false
    }
  },
  mounted () {
  },
  created () {
        
  },
  mounted () {
  },
  watch: {
        
  },
  methods: {
    zhuce () {
      if (this.phone.length != 0 && !this.phone_geshi) {
        if (!this.phone_zhuce) {
          if (this.code.length == 4) {
            if (this.pwd1.length != 0 && this.pwd2.length != 0 && !this.pwd1_geshi1 && this.pwd1_geshi2) {
              if (!this.pwd2_chongfu) {
                register({
                  data: {
                    mobile: this.phone,
                    sms: this.code,
                    pwd: this.pwd1
                  }
                }).then(res => {
                  if (res.data.code == 10006) {
                    this.$message({
                      message: '注册成功',
                      type: 'success'
                    })
                    this.$router.push('/login')
                  } else if (res.data.code == 10007) {
                    this.$message({
                      message: '注册失败',
                      type: 'error'
                    })
                  } else if (res.data.code == 10003) {
                    this.$message({
                      message: '验证码为空',
                      type: 'warning'
                    })
                  } else if (res.data.code == 10005) {
                    this.$message({
                      message: '验证码错误',
                      type: 'error'
                    })
                  }
                })
              } else {
                this.$message({
                  message: '两次输入密码不一致',
                  type: 'warning'
                })
              }
            } else {
              this.$message({
                message: '未填写完信息',
                type: 'warning'
              })
            }
          } else {
            this.$message({
              message: '验证码格式错误',
              type: 'warning'
            })
          }
        } else {
          this.$message({
            message: '该账号已注册',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '该账号格式不正确',
          type: 'warning'
        })
      }
    },
    phone_ju () {
      this.phone_geshi = false
      this.phone_zhuce = false
    },
    phone_shi () {
      if (/^1[3456789]\d{9}$/.test(this.phone)) {
        register_judge({
          data: {
            mobile: this.phone
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10008) {
            // 可以注册
          } else if (res.data.code == 10009) {
            this.phone_zhuce = true
          }
        })
      } else if (this.phone.length != 0) {
        this.phone_geshi = true
      }
    },
    dianji_yanzheng_ma () {
      if (!this.phone_zhuce && !this.phone_geshi) {
        if (!/^1[3456789]\d{9}$/.test(this.phone)) {
          this.$message({
            message: '手机号码有误，请重填',
            type: 'error'
          })
        } else {
          this.sms_a()
        }
      }
    },
    //   倒计时
    dao () {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.sj = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.sj > 0 && this.sj <= TIME_COUNT) {
            this.sj--
          } else {
            clearInterval(this.timer)
            this.timer = null
            this.sj = 0
          }
        }, 1000)
      }
    },
    // 验证码接口
    sms_a () {
      this.dao()
      sms({
        data: { mobile: this.phone }
      }).then(res => {
        if (res.data.code == 10000) {
          this.$message({
            message: '今日以超过最大值',
            type: 'warning'
          })
        } else if (res.data.code == 10001) {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          })
        } else if (res.data.code == 10002) {
          this.$message({
            message: '短信发送失败',
            type: 'error'
          })
        }
      })
    },
    pwd1_shi () {
      if (this.pwd1.length != 0) {
        if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.pwd1)) {
          this.pwd1_geshi2 = true
          this.pwd1_geshi1 = false
        } else {
          this.pwd1_geshi1 = true
          this.pwd1_geshi2 = false
        }
        if (this.pwd2.length != 0) {
          if (this.pwd1 == this.pwd2) {
            this.pwd2_chongfu = false
          } else {
            this.pwd2_chongfu = true
          }
        }
      }
    },
    pwd1_ju () {
      this.pwd1_geshi1 = false
      this.pwd1_geshi2 = false
    },
    pwd2_shi () {
      if (this.pwd1.length != 0) {
        if (this.pwd1 == this.pwd2) {
          this.pwd2_chongfu = false
        } else {
          this.pwd2_chongfu = true
        }
      }
    },
    pwd2_ju () {
      this.pwd2_chongfu = false
    },
    dianji_xiazai_yisi () {
      // this.$func.fujian_xiazai3('https://www.mlcbr.com/template/CBR智能经营数据模型用户隐私保护协议.docx','CBR智能经营数据模型用户隐私保护协议')
      this.$router.push('/privacy')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './denglu.scss';
</style>
